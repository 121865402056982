import React from 'react';
import { FaFacebook, FaInstagram, FaTwitter, FaGithub, FaYoutube } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-300 py-4">
      <div className="max-w-7xl mx-auto px-6 flex flex-col items-center justify-between sm:flex-row">
        {/* Copyright Section */}
        <p className="text-sm">
          &copy; {new Date().getFullYear()} Xipandza Mananga. All rights reserved.
        </p>

        {/* Social Media Icons */}
        <div className="flex space-x-6 mt-4 sm:mt-0">
          <a href="#" className="hover:text-white">
            <FaFacebook size={20} />
          </a>
          <a href="#" className="hover:text-white">
            <FaInstagram size={20} />
          </a>
          <a href="#" className="hover:text-white">
            <FaTwitter size={20} />
          </a>
          <a href="#" className="hover:text-white">
            <FaGithub size={20} />
          </a>
          <a href="#" className="hover:text-white">
            <FaYoutube size={20} />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
