'use client'

import React from 'react'
import { Helmet } from 'react-helmet'; // Import React Helmet


export default function About() {
  return (
    <div className="isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
         {/* Helmet for dynamic page title and favicon */}
       <Helmet>
        <title>Xipandza Mananga</title>
        <link rel="icon" href="/logo.png" type="image/png" /> {/* Favicon set to logo */}
      </Helmet>

<div className="mx-auto max-w-2xl text-center bg-white p-8 rounded-lg shadow-lg">

      <div
        aria-hidden="true"
        className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
      >
        <div
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
          className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
        />
      </div>
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-balance text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">About Me</h2>
        <p className="mt-2 text-lg/8 text-gray-600">
          I’m a passionate wildlife photographer & videographer focused on capturing the beauty of nature through my lens. 
          My journey started years ago, and through my lens, I aim to bring you closer to the wild, one shot at a time.
        </p>
      </div>
      <div className="mx-auto mt-16 max-w-3xl sm:mt-20">
        <h3 className="text-2xl font-semibold text-gray-900 sm:text-3xl">My Photography Journey</h3>
        <p className="mt-4 text-lg/8 text-gray-600">
          From traveling to remote locations to spending countless hours in the wild, I have honed my skills 
          and grown as a photographer. Each moment I capture is an opportunity to tell a story, evoke emotions, 
          and preserve the beauty of wildlife for generations to come.
        </p>

        <p className="mt-4 text-lg/8 text-gray-600">
        I believe in the power of photography to inspire change. Join me on this incredible journey as we explore 
        the breathtaking landscapes and wildlife that this planet has to offer.
        </p>
      </div>
      </div>

    </div>
  )
}
