import React, { useState } from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink

const Navbar = () => {
  // State to manage the mobile menu visibility
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Toggle the menu visibility on mobile
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Close the mobile menu when a menu item is clicked
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav className="fixed w-full top-0 bg-gray-900 text-white p-4 shadow-md z-50">
      <div className="max-w-7xl mx-auto px-6 flex justify-between items-center">
        {/* Logo and Brand Name (Left side) */}
        <div className="flex items-center space-x-3">
          {/* Logo Image */}
          <img src="/logo.png" alt="Xipandza Mananga Logo" className="h-20 w-auto" />
          {/* Brand Name */}
          <div className="text-2xl font-bold">
            <NavLink to="/">Xipandza Mananga</NavLink>
          </div>
        </div>

        {/* Hamburger Menu for Mobile */}
        <div className="block lg:hidden">
          <button
            onClick={toggleMenu}
            className="text-white focus:outline-none"
          >
            {/* Hamburger icon (3 bars) */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>

        {/* Navigation Links (Desktop and Mobile) */}
        <ul
          className={`lg:flex space-x-8 ml-auto ${isMenuOpen ? 'block' : 'hidden'} lg:block`}
        >
          <li>
            <NavLink
              to="/"
              onClick={closeMenu}  // Close the menu when a menu item is clicked
              className={({ isActive }) =>
                `hover:text-orange-400 transition duration-300 ${isActive ? 'border-b-2 border-orange-500' : ''}`
              }
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/portfolio"
              onClick={closeMenu}  // Close the menu when a menu item is clicked
              className={({ isActive }) =>
                `hover:text-blue-400 transition duration-300 ${isActive ? 'border-b-2 border-blue-500' : ''}`
              }
            >
              Gallery
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/travel"
              onClick={closeMenu}  // Close the menu when a menu item is clicked
              className={({ isActive }) =>
                `hover:text-orange-400 transition duration-300 ${isActive ? 'border-b-2 border-orange-500' : ''}`
              }
            >
              Travel
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about"
              onClick={closeMenu}  // Close the menu when a menu item is clicked
              className={({ isActive }) =>
                `hover:text-orange-400 transition duration-300 ${isActive ? 'border-b-2 border-orange-500' : ''}`
              }
            >
              About
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact"
              onClick={closeMenu}  // Close the menu when a menu item is clicked
              className={({ isActive }) =>
                `hover:text-orange-400 transition duration-300 ${isActive ? 'border-b-2 border-orange-500' : ''}`
              }
            >
              Contact
            </NavLink>
          </li>
        </ul>

        {/* Optional: Notification or Profile (Desktop) */}
        <div className="hidden lg:flex items-center space-x-6">
          <button className="text-lg hover:text-gray-400">
            <i className="fas fa-bell"></i> {/* You can add an icon here */}
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
